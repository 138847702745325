export const LineHeightVariantsValues = {
    tight: "tight",
    relaxed: "relaxed",
};
export const WeightSize = {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
};
export const VariantByDevice = {
    "text-4xs": {
        lg: "12px",
        md: "12px",
        sm: "12px",
        xs: "12px",
    },
    "text-3xs": {
        lg: "14px",
        md: "14px",
        sm: "14px",
        xs: "14px",
    },
    "text-2xs": {
        lg: "16px",
        md: "16px",
        sm: "16px",
        xs: "16px",
    },
    "text-xs": {
        lg: "24px",
        md: "20px",
        sm: "18px",
        xs: "18px",
    },
    "text-sm": {
        lg: "28px",
        md: "24px",
        sm: "20px",
        xs: "20px",
    },
    "text-md": {
        lg: "32px",
        md: "28px",
        sm: "24px",
        xs: "24px",
    },
    "text-lg": {
        lg: "36px",
        md: "32px",
        sm: "28px",
        xs: "28px",
    },
    "text-xl": {
        lg: "40px",
        md: "36px",
        sm: "32px",
        xs: "32px",
    },
    "text-2xl": {
        lg: "48px",
        md: "40px",
        sm: "36px",
        xs: "36px",
    },
    "text-3xl": {
        lg: "56px",
        md: "48px",
        sm: "44px",
        xs: "40px",
    },
    "text-4xl": {
        lg: "64px",
        md: "56px",
        sm: "48px",
        xs: "44px",
    },
    "text-xxl": {
        lg: "72px",
        md: "64px",
        sm: "56px",
        xs: "48px",
    },
};
export const LineHeightByDevice = {
    "text-4xs": {
        tight: {
            lg: "14px",
            md: "14px",
            sm: "14px",
            xs: "14px",
        },
        relaxed: {
            lg: "16px",
            md: "16px",
            sm: "16px",
            xs: "16px",
        },
    },
    "text-3xs": {
        tight: {
            lg: "16px",
            md: "16px",
            sm: "16px",
            xs: "16px",
        },
        relaxed: {
            lg: "20px",
            md: "20px",
            sm: "20px",
            xs: "20px",
        },
    },
    "text-2xs": {
        tight: {
            lg: "20px",
            md: "20px",
            sm: "20px",
            xs: "20px",
        },
        relaxed: {
            lg: "24px",
            md: "24px",
            sm: "24px",
            xs: "24px",
        },
    },
    "text-xs": {
        tight: {
            lg: "24px",
            md: "24px",
            sm: "24px",
            xs: "24px",
        },
        relaxed: {
            lg: "28px",
            md: "28px",
            sm: "28px",
            xs: "28px",
        },
    },
    "text-sm": {
        tight: {
            lg: "32px",
            md: "32px",
            sm: "24px",
            xs: "24px",
        },
        relaxed: {
            lg: "36px",
            md: "36px",
            sm: "28px",
            xs: "28px",
        },
    },
    "text-md": {
        tight: {
            lg: "36px",
            md: "36px",
            sm: "32px",
            xs: "32px",
        },
        relaxed: {
            lg: "32px",
            md: "28px",
            sm: "24px",
            xs: "24px",
        },
    },
    "text-lg": {
        tight: {
            lg: "40px",
            md: "40px",
            sm: "36px",
            xs: "36px",
        },
        relaxed: {
            lg: "36px",
            md: "32px",
            sm: "28px",
            xs: "28px",
        },
    },
    "text-xl": {
        tight: {
            lg: "44px",
            md: "44px",
            sm: "40px",
            xs: "4-px",
        },
        relaxed: {
            lg: "40px",
            md: "36px",
            sm: "32px",
            xs: "32px",
        },
    },
    "text-2xl": {
        tight: {
            lg: "48px",
            md: "48px",
            sm: "44px",
            xs: "44px",
        },
        relaxed: {
            lg: "48px",
            md: "40px",
            sm: "36px",
            xs: "36px",
        },
    },
    "text-3xl": {
        tight: {
            lg: "56px",
            md: "56px",
            sm: "48px",
            xs: "48px",
        },
        relaxed: {
            lg: "56px",
            md: "48px",
            sm: "44px",
            xs: "40px",
        },
    },
    "text-4xl": {
        tight: {
            lg: "64px",
            md: "64px",
            sm: "52px",
            xs: "52px",
        },
        relaxed: {
            lg: "64px",
            md: "56px",
            sm: "48px",
            xs: "44px",
        },
    },
    "text-xxl": {
        tight: {
            lg: "72px",
            md: "68px",
            sm: "56px",
            xs: "56px",
        },
        relaxed: {
            lg: "72px",
            md: "64px",
            sm: "56px",
            xs: "48px",
        },
    },
};
export const VariantsValues = {
    "text-4xs": "text-4xs",
    "text-3xs": "text-3xs",
    "text-2xs": "text-2xs",
    "text-xs": "text-xs",
    "text-sm": "text-sm",
    "text-md": "text-md",
    "text-lg": "text-lg",
    "text-xl": "text-xl",
    "text-2xl": "text-2xl",
    "text-3xl": "text-3xl",
    "text-4xl": "text-4xl",
    "text-xxl": "text-xxl",
};
