import { FtsSideOutWrapperHeader } from "../dist/ui/src/FtsSideOut/FtsSideOutWrapperHeader";
import { ColorsValue } from "../dist/types/ui/colors";

import { Span } from "../dist/ui/src/FtsText";
import { FtsWrapper } from "../dist/ui/src/FtsWrapper/FtsWrapper";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectListingData } from "../../../redux/reducers/v2/listing";
import {
  closeSideOutAction,
  openEditAccountSubSideOutAction,
} from "../../../redux/actions/v2/sideOut";
import { selectListingStatuses } from "../../../redux/reducers/essentialLists";
import { Dropdown } from "../dist/ui/src/components/react/Dropdown";
import Icon from "../../fts/Icons";

import { getListingDataByIdSuccess } from "../../../redux/actions/v2/listings";
import { Spacing } from "../dist/types/ui/spacing";
import { Menu } from "../dist/ui/src/components/react/Menu";
import FtsIcons from "../dist/ui/src/FtsIcons/FtsIcons";

export const SideOutEditListingHeader = () => {
  const buttonRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const listingData = useSelector(selectListingData);
  const listingStatus = useSelector(selectListingStatuses);
  const hideSidebar = useCallback(() => {
    dispatch(closeSideOutAction());
  }, [closeSideOutAction]);

  const onStatusChange = useCallback(
    (option) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            status: option?.value || 0,
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsSideOutWrapperHeader bg={"greyscale-100"} py={"lg"} px={"3xl"}>
      <FtsWrapper>
        <Span variant={"text-sm"} color={"greyscale-800"}>
          Edit listing #{listingData.id}
        </Span>
      </FtsWrapper>

      <FtsWrapper display="flex" align={"center"}>
        <FtsWrapper display="flex" align={"center"} width={"180px"}>
          <Span mr={"2xs"} variant={"text-3xs"} color={"greyscale-500"}>
            Status*
          </Span>
          <Dropdown
            isClearable={false}
            size={"sm"}
            options={listingStatus}
            value={listingStatus.find((e) => e.value === listingData.status)}
            onChange={onStatusChange}
          />
        </FtsWrapper>
        <FtsWrapper
          ref={buttonRef}
          position={"relative"}
          p={"xs"}
          borderRadius={"2xs"}
          border={`${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}`}
          mr={"2xl"}
          ml={"md"}
          onClick={() => setIsMenuOpen((prev) => !prev)}
        >
          <FtsIcons
            iconName={"MoreVertical"}
            pathProps={{ stroke: "#0077CD" }}
          />
          <Menu
            parentRef={buttonRef}
            menuItems={[
              {
                id: 1,
                title: "Edit account",
                iconName: "Edit",
                onClick: () =>
                  // dispatch(openEditAccountSideOutAction({ id: listingData.userId })),
                  dispatch(
                    openEditAccountSubSideOutAction({
                      data: { id: listingData.userId },
                    }),
                  ),
              },
              {
                id: 2,
                title: "View account",
                iconName: "EyeIcon",
                onClick: () =>
                  window.open(
                    `/accounts/${listingData.userId}`,
                    "_blank",
                    "noopener,noreferrer",
                  ),
              },
            ]}
            isOpen={isMenuOpen}
          />
        </FtsWrapper>
        <Icon onClick={hideSidebar} size={24} name={"Close"} />
      </FtsWrapper>
    </FtsSideOutWrapperHeader>
  );
};
