import React from "react";
import ReactQuill from "react-quill";
import { InputLabel } from "../InputLabel";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";
export const RichTextEditor = (props) => {
    const { value, placeholder, onChange, label, required } = props;
    const height = props.height || "256px";
    const width = props.width || "100%";
    return (React.createElement(FtsWrapper, null,
        React.createElement(InputLabel, { label: label, mb: "2xs", required: required }),
        React.createElement(ReactQuill, { id: "rich_text_editor", className: "rich-text-editor", theme: "snow", value: value ?? "", onChange: onChange, placeholder: placeholder, style: { height, width }, modules: {
                toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
            } })));
};
