import React, { PureComponent } from "react";
import ColumnItem from "./ColumnItem";
import SkeletonCard from "../../../components/fts/skeleton-card";

import {
  getTodo,
  updateTodo,
  archiveTodo,
  assignTodoToListing,
} from "../../../redux/actions/todo";

import { connect } from "react-redux";

class ColumnTasks extends PureComponent {
  render() {
    const {
      column,
      tasks,
      innerRef,
      provided,
      callToAction,
      loading,
      isDraggingOver,
      activeDropdown,
      setActiveDropdown,
      handleArchive,
      handleUnArchive,
      handleTodoTypeUpdate,
      handleTodoUsersUpdate,
      handleEmptyCols,
      getEmptyColumns,
      list,
      userRef,
      user,
    } = this.props;
    let skeletons = [];
    if (loading) {
      const min = 1;
      const max = 4;
      const rand = min + Math.random() * (max - min);
      let i = 1;
      while (i <= rand) {
        skeletons.push(<SkeletonCard key={i} />);
        i++;
      }
    }

    let columnTasks = tasks;

    return (
      <>
        {columnTasks ? (
          <div
            ref={innerRef}
            className={`column-items ${
              isDraggingOver ? "column-items-dragging-over" : ""
            }`}
            {...provided.droppableProps}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ position: "relative" }}
          >
            {loading ? (
              <>{skeletons}</>
            ) : (
              columnTasks.length !== 0 && (
                <>
                  {isDraggingOver && (
                    <div
                      style={{
                        backgroundColor: "rgba(255,255,255,0.8)",
                        width: "100%",
                        height: "80vh",
                        position: "absolute",
                        zIndex: 2,
                      }}
                    />
                  )}

                  {columnTasks.map((itm, index) => (
                    <ColumnItem
                      key={itm.id}
                      item={itm}
                      callToAction={callToAction}
                      activeDropdown={activeDropdown}
                      setActiveDropdown={setActiveDropdown}
                      handleArchive={handleArchive}
                      handleUnArchive={handleUnArchive}
                      handleTodoTypeUpdate={handleTodoTypeUpdate}
                      handleTodoUsersUpdate={handleTodoUsersUpdate}
                      handleEmptyCols={handleEmptyCols}
                      getEmptyColumns={getEmptyColumns}
                      list={list}
                      userRef={userRef}
                      user={user}
                      {...{ index, column }}
                    />
                  ))}
                </>
              )
            )}
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(null, {
  getTodo,
  updateTodo,
  archiveTodo,
  assignTodoToListing,
})(ColumnTasks);
