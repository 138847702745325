import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { P } from "../../dist/ui/src/FtsText";

import React from "react";

export const ListingInfoItem = ({ label, value }) => {
  return (
    <FtsColumn
      size={{
        xs: 3,
      }}
    >
      <P mb={"xs"} variant={"text-3xs"} color={"greyscale-500"}>
        {label}
      </P>
      <P
        mb={"0"}
        variant={"text-3xs"}
        weight={"medium"}
        color={"greyscale-800"}
      >
        {value}
      </P>
    </FtsColumn>
  );
};
