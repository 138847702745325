import { FtsColumn } from "@find-truck-service/ui/src/FtsColumn/FtsColumn";
import { Span } from "@find-truck-service/ui/src/FtsText";
import React from "react";
import { FtsRow } from "@find-truck-service/ui/src/FtsRow/FtsRow";

export const AccountAltContactWrapper = ({ children }) => {
  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span weight={"medium"} variant={"text-3xs"} color={"blue-accent-500"}>
          ALT CONTACTS
        </Span>
      </FtsColumn>
      {children}
    </FtsRow>
  );
};
