import React, { useCallback } from "react";
import { accountToggles } from "../constants";
import { Span } from "../../dist/ui/src/FtsText";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { ToggleSwitch } from "../../dist/ui/src/components/react/Toggle";
import { selectAccountData } from "../../../../redux/reducers/v2/account";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";

export const AccountTogglesRow = () => {
  const dispatch = useDispatch();
  const accountData = useSelector(selectAccountData);

  const handleToggle = useCallback(
    (name, value) =>
      dispatch(
        setAccountDataAction({
          ...accountData,
          [name]: !value,
        }),
      ),
    [accountData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span weight={"medium"} variant={"text-3xs"} color={"blue-accent-500"}>
          TURN ON
        </Span>
      </FtsColumn>
      {accountToggles.map(({ name, label, isReverseLogic }) => (
        <FtsColumn size={{ xs: 3 }} key={name}>
          <ToggleSwitch
            label={label}
            checked={isReverseLogic ? !accountData[name] : !!accountData[name]}
            onClick={() => handleToggle(name, accountData[name])}
          />
        </FtsColumn>
      ))}
    </FtsRow>
  );
};
