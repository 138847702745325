export const checkboxOptLabels = ["Marketing", "Renewal", "Update", "Repair"];
export const mobileOptIn = [
    "mobileMarketingOptIn",
    "mobileRenewalOptIn",
    "mobileUpdateOptIn",
];
export const primaryOptIn = [
    "primaryMarketingOptIn",
    "primaryRenewalsOptIn",
    "primaryUpdatesOptIn",
];
