import { Dropdown } from "../Dropdown";
import { Chips } from "../Chips";
import React from "react";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { components } from "react-select";
export const CategoryWithSubCategoryDropdown = (props) => {
    return (React.createElement(Dropdown, { hideSelectedOptions: false, closeMenuOnSelect: false, components: {
            Option: (item) => {
                return (React.createElement(FtsWrapper, { cursor: "pointer", onClick: () => item.selectOption(item), ...item, display: "flex", justify: "space-between", px: "xl", py: "xs", bg: item.isSelected ? "greyscale-50" : "greyscale-0" },
                    React.createElement(Span, { variant: "text-2xs" }, item.children),
                    item.isSelected && (React.createElement(FtsIcons, { width: "20px", height: "20px", iconName: "Check", pathProps: { stroke: ColorsValue["green-success-300"] } }))));
            },
            MultiValueRemove: (item) => {
                return (React.createElement(components.MultiValueRemove, { ...item },
                    React.createElement(FtsIcons, { ...item, iconName: "Close" })));
            },
            MultiValueContainer: (item) => {
                return (React.createElement(Chips, { display: "flex", label: item.children, size: "sm", variant: "contained", textColor: "greyscale-0", color: "blue-accent-50", ...item }));
            },
            MultiValue: (item) => {
                return (React.createElement(components.MultiValue, { ...item },
                    React.createElement(Span, { variant: "text-4xs" }, item.children)));
            },
        }, ...props, options: props.options }));
};
