"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INVALID_ZIP = exports.INVALID_EXPIRATION = exports.INVALID_CARD_NUMBER = exports.INVALID_CVV_CODE = exports.INVALID_PHONE_NUMBER = exports.INVALID_URL = exports.REQUIRED_FIELD = void 0;
exports.REQUIRED_FIELD = "This field is required";
exports.INVALID_URL = "Invalid url!";
exports.INVALID_PHONE_NUMBER = "Phone must be a valid phone number";
exports.INVALID_CVV_CODE = "CVV code is invalid";
exports.INVALID_CARD_NUMBER = "Card number is invalid";
exports.INVALID_EXPIRATION = "Expiration date is invalid";
exports.INVALID_ZIP = "Zip code is invalid";
