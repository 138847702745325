import React from "react";
import { P } from "../../dist/ui/src/FtsText";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";

export const AccountInfoItem = ({
  label,
  value,
  valueColor = "greyscale-500",
}) => {
  return (
    <FtsColumn size={{ xs: 3 }}>
      <P mb={"xs"} variant={"text-3xs"} color={"greyscale-500"}>
        {label}
      </P>
      <P mb={"0"} variant={"text-3xs"} weight={"medium"} color={valueColor}>
        {value}
      </P>
    </FtsColumn>
  );
};
