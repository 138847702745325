import { Span } from "../../../FtsText";
import React from "react";
import { EmptyComponent } from "../EmptyComponent";
import { IsRequiredInputIndicator } from "../IsRequiredInputIndicator";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
export const InputLabel = (props) => {
    const { label, mb, ml, required } = props;
    if (!label)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(FtsWrapper, { mb: mb, ml: ml },
        React.createElement(Span, { variant: "text-3xs", color: "greyscale-500" }, label),
        required && React.createElement(IsRequiredInputIndicator, null)));
};
// borderWidth: 0,
//   control: (styles: any, props: any) => {
//   return {
//     ...styles,
//     width: "100%",
//     border: "unset",
//     cursor: "pointer",
//     minHeight: "unset",
//     borderRadius: `${Spacing["2xs"]}`,
//     color: `${ColorsValue["greyscale-800"]}`,
//     outline: `${props.selectProps.withoutBorder ? Spacing["0"] : Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`,
//     "&::placeholder": {
//       color: `${ColorsValue["greyscale-500"]}`,
//       opacity: 1,
//     },
//     padding: props.selectProps.withoutBorder ? Spacing["0"] : Spacing[props.selectProps.size as keyof SpacingSizes],
//     "&:hover": {
//       outline: `${props.selectProps.withoutBorder ? Spacing["0"] : Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}`,
//     },
//     "&:focus": {
//       outline: `${Spacing["3xs"]} solid ${ColorsValue["blue-accent-500"]}`,
//     },
//     "&:active": {
//       outline: `${Spacing["3xs"]} solid ${ColorsValue["blue-accent-500"]}`,
//     },
//   };
// },
//   option: (styles: any) => ({
//   ...styles,
//   textAlign: "left",
// }),
//   input: (styles: any) => ({
//   ...styles,
//   margin: 0,
//   padding: 0,
// }),
//   valueContainer: (styles: any) => ({
//   ...styles,
//   padding: Spacing["0"],
//   margin: Spacing["0"],
//   gap: Spacing["xs"],
// }),
//
//   multiValueRemove: (styles: any) => ({
//   ...styles,
//   padding: Spacing["0"],
//   margin: Spacing["0"],
//   marginLeft: Spacing["3xs"],
//
//   ":hover": {
//     backgroundColor: ColorsValue["blue-accent-100"],
//   },
// }),
//
//   multiValueLabel: (styles: any) => ({
//   ...styles,
//   borderRadius: 0,
//   fontSize: `unset`,
//   padding: Spacing["0"],
//   paddingLeft: Spacing["0"],
//   margin: Spacing["0"],
// }),
