import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
//types
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { Span } from "@find-truck-service/ui/src/FtsText";
import { FtsWrapper } from "@find-truck-service/ui/src/FtsWrapper/FtsWrapper";
//Component
import { InputLabel } from "@find-truck-service/ui/src/components/react/InputLabel";
export const DropdownVariantsValues = {
    outlined: "outlined",
    text: "text",
};
const defaultPickerStyle = {
    control: (styles) => {
        return {
            ...styles,
            width: "100%",
            border: "unset",
            cursor: "pointer",
            minHeight: "unset",
            borderRadius: `${Spacing["2xs"]}`,
            color: `${ColorsValue["greyscale-800"]}`,
            boxShadow: "unset",
            borderWidth: "0px",
            "&::placeholder": {
                color: `${ColorsValue["greyscale-500"]}`,
                opacity: 1,
            },
        };
    },
    option: (styles) => ({
        ...styles,
        textAlign: "left",
    }),
    input: (styles) => ({
        ...styles,
        margin: 0,
        padding: 0,
    }),
    valueContainer: (styles) => ({
        ...styles,
        boxShadow: "unset",
        padding: Spacing["0"],
        margin: Spacing["0"],
        gap: Spacing["xs"],
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        padding: Spacing["0"],
        margin: Spacing["0"],
        marginLeft: Spacing["3xs"],
        ":hover": {
            backgroundColor: ColorsValue["blue-accent-100"],
        },
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        borderRadius: 0,
        fontSize: `unset`,
        padding: Spacing["0"],
        paddingLeft: Spacing["0"],
        margin: Spacing["0"],
    }),
};
const pickerStyle = {
    [DropdownVariantsValues.outlined]: {
        ...defaultPickerStyle,
        control: (styles, props) => {
            return {
                ...defaultPickerStyle.control(styles),
                padding: Spacing[props.selectProps.size],
                outline: `${Spacing[props.menuIsOpen ? "3xs" : "4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-300" : props.menuIsOpen ? "blue-accent-500" : "greyscale-100"]}`,
                "&:hover": {
                    outline: !props.menuIsOpen &&
                        `${Spacing["4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-700" : "blue-accent-300"]}`,
                },
                "&:active": {
                    outline: `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
                },
            };
        },
    },
    [DropdownVariantsValues.text]: {
        ...defaultPickerStyle,
        control: (styles, props) => ({
            ...defaultPickerStyle.control(styles),
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            height: "16px",
            outline: `${Spacing[props.menuIsOpen || props.selectProps.invalid ? "3xs" : "0"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-300" : "blue-accent-500"]}`,
            "&:hover": {
                outline: !props.menuIsOpen &&
                    `${Spacing["4xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-700" : "blue-accent-300"]}`,
            },
            "&:active": {
                outline: `${Spacing["3xs"]} solid ${ColorsValue[props.selectProps.invalid ? "red-error-50" : "blue-accent-500"]}`,
            },
        }),
        valueContainer: (styles) => ({
            ...styles,
            height: "16px",
            padding: 0,
            paddingRight: Spacing["sm"],
            margin: 0,
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            svg: {
                width: "16px",
                height: "16px",
            },
        }),
        singleValue: (styles) => ({
            ...styles,
            margin: 0,
        }),
    },
};
const IndicatorContainer = styled(components.IndicatorsContainer) `
  & > div {
    padding: 0;
  }
`;
export const Dropdown = (props) => {
    return (React.createElement(FtsWrapper, { width: "100%", display: "flex", gap: "2xs", direction: "column" },
        !!props.label && React.createElement(InputLabel, { label: props.label, required: props.required }),
        React.createElement(FtsWrapper, null,
            React.createElement(Select, { isSearchable: true, isClearable: true, invalid: props.errorMessage, options: props.options, styles: pickerStyle[props.variant || DropdownVariantsValues.outlined], onChange: props.onChange, ...props, components: {
                    IndicatorSeparator: () => null,
                    IndicatorsContainer: IndicatorContainer,
                    ...props.components,
                } })),
        !!props.errorMessage && (React.createElement(FtsWrapper, null,
            React.createElement(Span, { variant: "text-3xs", color: "red-error-500" }, props.errorMessage)))));
};
