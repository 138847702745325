export const ButtonVariants = {
    contained: "contained",
    outlined: "outlined",
    text: "text",
};
export const ButtonColorsValue = {
    accent: "accent",
    error: "error",
    success: "success",
    warning: "warning",
};
export const ButtonSizes = {
    sm: "sm",
    md: "md",
};
