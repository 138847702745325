import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";
import {
  selectListingData,
  selectListingRates,
} from "../../../../redux/reducers/v2/listing";
import { Input } from "../../dist/ui/src/components/react/Input";
import {
  InputColors,
  InputSizes,
  InputVariants,
} from "../../dist/types/ui/input";
import { Dropdown } from "../../dist/ui/src/components/react/Dropdown";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";

export const minimumChargeOptions = [
  { label: "30 min", value: "30 min" },
  { label: "1h", value: "1h" },
  { label: "1h 30 min", value: "1h 30 min" },
  { label: "2h", value: "2h" },
  { label: "2h 30 min", value: "2h 30 min" },
];

export const ListingRatesRow = () => {
  const selectedRates = useSelector(selectListingRates);
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();
  const onChange = useCallback(
    (rateId, value) => {
      let newListingData = { ...listingData };
      const indexOfRate = selectedRates.findIndex((e) => e.rate.id === rateId);
      newListingData.adsRates[indexOfRate] = {
        ...newListingData.adsRates[indexOfRate],
        value,
      };
      dispatch(getListingDataByIdSuccess({ data: newListingData }));
    },
    [listingData],
  );

  if (selectedRates.length) {
    return (
      <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
        <FtsColumn size={{ xs: 12 }}>
          <Span
            weight={"medium"}
            variant={"text-3xs"}
            color={"blue-accent-500"}
          >
            Rates
          </Span>
        </FtsColumn>

        {selectedRates
          .filter((e) => e.rate.status)
          .map((e) => {
            if (e.rate.id === 5) {
              return (
                <FtsColumn key={e.id} size={{ xs: 3 }}>
                  <Dropdown
                    size={"sm"}
                    options={minimumChargeOptions}
                    label={e.rate.name}
                    name={e.rate.name}
                    value={{ label: e.value, value: e.value }}
                    // onChange={onChange}
                    onChange={(event) =>
                      onChange(e.rate.id, event?.value || "")
                    }
                  />
                </FtsColumn>
              );
            }
            return (
              <FtsColumn key={e.id} size={{ xs: 3 }}>
                <Input
                  name={e.id}
                  value={e.value}
                  onChange={(event) => onChange(e.rate.id, event.target.value)}
                  label={e.rate.name}
                  variant={InputVariants.outlined}
                  size={InputSizes.sm}
                  color={InputColors.accent}
                />
              </FtsColumn>
            );
          })}
      </FtsRow>
    );
  }
  return null;
};
