import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";
import { Input } from "../../dist/ui/src/components/react/Input";
import {
  InputColors,
  InputSizes,
  InputVariants,
} from "../../dist/types/ui/input";
import Checkbox from "../../dist/ui/src/components/react/Checkbox";
import { Dropdown } from "../../dist/ui/src/components/react/Dropdown";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListingData,
  selectListingInvalidFormFields,
} from "../../../../redux/reducers/v2/listing";
import { selectStates } from "../../../../redux/reducers/essentialLists";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { capitalizeFirstLetter } from "../../../../utility/googleCalendar";

import isObject from "lodash/isObject";

export const ListingDetailsRow = () => {
  const listingData = useSelector(selectListingData);
  const invalidFormFields = useSelector(selectListingInvalidFormFields);
  const dispatch = useDispatch();
  const states = useSelector(selectStates);

  const onChange = useCallback(
    (event) => {
      if (event.target.name === "city") {
        if (event.target.value) {
          event.target.value = capitalizeFirstLetter(event.target.value);
        }
      }
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            [event.target.name]: event.target.value,
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"}>
      <FtsColumn size={{ xs: 12 }} mt={"2xl"}>
        <Span weight={"medium"} variant={"text-3xs"} color={"blue-accent-500"}>
          LISTING DETAILS
        </Span>
      </FtsColumn>

      <FtsColumn size={{ xs: 10 }}>
        <Input
          name={"name"}
          value={listingData.name}
          onChange={onChange}
          label={"Company name*"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["name"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 2 }} display={"flex"} align={"flex-end"}>
        <Checkbox
          name={"includeInAuthorizedPromotions"}
          checked={listingData.includeInAuthorizedPromotions}
          errorMessage={
            isObject(invalidFormFields) &&
            invalidFormFields["includeInAuthorizedPromotions"]
          }
          onClick={() => {
            !!listingData.user.adminIsMerchant &&
              onChange({
                target: {
                  name: "includeInAuthorizedPromotions",
                  value: !listingData.includeInAuthorizedPromotions,
                },
              });
          }}
          label={"AVP Banner"}
          mb={"sm"}
        />
      </FtsColumn>

      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"address"}
          value={listingData.address}
          onChange={onChange}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["address"]
          }
          label={"Street*"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <FtsRow columnGap={"lg"} rowGap={"lg"}>
          <FtsColumn size={{ xs: 6 }}>
            <Input
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["city"]
              }
              name={"city"}
              value={listingData.city}
              onChange={onChange}
              label={"City*"}
              variant={InputVariants.outlined}
              size={InputSizes.sm}
              color={InputColors.accent}
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 3 }}>
            <Dropdown
              // isClearable={false}
              size={"sm"}
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["state"]
              }
              options={states}
              required
              label={"State"}
              name={"state"}
              value={states.find((e) => e.value === listingData.state)}
              onChange={(val) =>
                onChange({ target: { name: "state", value: val?.label || "" } })
              }
            />
          </FtsColumn>
          <FtsColumn size={{ xs: 3 }}>
            <Input
              errorMessage={
                isObject(invalidFormFields) && invalidFormFields["zip"]
              }
              name={"zip"}
              value={listingData.zip}
              onChange={onChange}
              pattern="\d*"
              maxLength="5"
              label={"Zip*"}
              variant={InputVariants.outlined}
              size={InputSizes.sm}
              color={InputColors.accent}
            />
          </FtsColumn>
        </FtsRow>
      </FtsColumn>

      <FtsColumn size={{ xs: 6 }}>
        <Input
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["interstate"]
          }
          name={"interstate"}
          value={listingData.interstate}
          onChange={onChange}
          label={"Interstate"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["milemarker"]
          }
          name={"milemarker"}
          value={listingData.milemarker}
          onChange={onChange}
          label={"Mile marker"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
        />
      </FtsColumn>
    </FtsRow>
  );
};
