import styled from "styled-components";
export const FtsSideOutWrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
  z-index: ${(props) => (props.isSubSideOut ? 1034 : 1032)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;
