import { FtsChipsSizes, FtsChipsVariants } from "@find-truck-service/types/ui/chips";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
const defaultStyle = `
display: flex;
align-items: center;
width: fit-content;
height: fit-content;
border-radius: ${Spacing["2xs"]};
`;
export const FtsChipsStyles = (props) => {
    let style = {
        [FtsChipsVariants.outlined]: {
            [FtsChipsSizes.sm]: `${defaultStyle}; padding: ${Spacing["2xs"]}; border: ${Spacing["4xs"]} ${ColorsValue[props.color]} solid;`,
            [FtsChipsSizes.md]: `${defaultStyle}; padding: ${Spacing["xs"]}; border: ${Spacing["4xs"]} ${ColorsValue[props.color]} solid;`,
            [FtsChipsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]}; border: ${Spacing["4xs"]} ${ColorsValue[props.color]} solid;`,
        },
        [FtsChipsVariants.contained]: {
            [FtsChipsSizes.sm]: `${defaultStyle}; padding: ${Spacing["2xs"]};background:${ColorsValue[props.color]}`,
            [FtsChipsSizes.md]: `${defaultStyle}; padding: ${Spacing["xs"]};background:${ColorsValue[props.color]}`,
            [FtsChipsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]};background:${ColorsValue[props.color]}`,
        },
    };
    return style[props.variant][props.size];
};
