import React from "react";
import { FtsAccordionContainer } from "../../../FtsAccordion/FtsAccordionContainer";
import { FtsAccordionHeader } from "../../../FtsAccordion/FtsAccordionHeader";
import { FtsAccordionDescription } from "../../../FtsAccordion/FtsAccordionDescription";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
export const Accordion = (props) => {
    const { headerLabel, children, show, toggleShowAccordionDescription } = props;
    return (React.createElement(FtsAccordionContainer, null,
        React.createElement(FtsAccordionHeader, { onClick: toggleShowAccordionDescription, show: show },
            React.createElement(Span, { variant: "text-2xs", color: "greyscale-800" }, headerLabel),
            React.createElement(FtsIcons, { iconName: "ChevronDown" })),
        React.createElement(FtsAccordionDescription, { show: show }, children)));
};
