import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
export const FtsLoaderIndicatorWrapper = styled(FtsWrapper) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #eceff1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 2s ease;
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  visibility: ${(props) => (props.isLoading ? "visible" : "hidden")};,
`;
