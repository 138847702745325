import React, { useEffect, useRef, useState } from "react";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { EmptyComponent } from "../EmptyComponent";
import { Span } from "../../../FtsText";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import styled from "styled-components";
const StyledWrapper = styled(FtsWrapper) `
  top: ${(props) => props.parentHeight}px;
  left: ${(props) => props.parentWidth}px;
`;
const MenuWidth = 232;
export const Menu = (props) => {
    const [parentWidth, setParentWidth] = useState(0);
    const [parentHeight, setParentHeight] = useState(0);
    useEffect(() => {
        const handleResize = () => {
            if (props.parentRef.current) {
                const rect = props.parentRef.current.getBoundingClientRect();
                const { innerWidth: width } = window;
                if (width - rect.left - props.parentRef.current.clientWidth < MenuWidth) {
                    setParentWidth(-MenuWidth);
                    setParentHeight(props.parentRef.current.clientHeight);
                    return;
                }
                setParentWidth(props.parentRef.current.clientWidth);
                setParentHeight(props.parentRef.current.clientHeight);
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (props.parentRef.current) {
            resizeObserver.observe(props.parentRef.current);
        }
        handleResize();
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    if (!props.isOpen)
        return React.createElement(EmptyComponent, null);
    return (React.createElement(StyledWrapper, { parentWidth: parentWidth, parentHeight: parentHeight, bg: "greyscale-0", position: "absolute", width: `${MenuWidth}px`, p: "2xs", borderRadius: "2xs", border: `${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]}`, boxShadow: "sm" }, props.menuItems.map((menuItem) => (React.createElement(MenuItem, { ...menuItem })))));
};
const MenuItem = (props) => {
    const parentRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    if (props.subMenu)
        return (React.createElement(MenuItemStyled, { ref: parentRef, position: "relative", key: props.id, p: "sm", gap: "sm", display: "flex", align: "center", onMouseOver: () => setIsMenuOpen(true) },
            props.iconName && React.createElement(FtsIcons, { iconName: props.iconName, width: "20px", height: "20px" }),
            React.createElement(Span, { variant: "text-3xs" }, props.title),
            React.createElement(Menu, { parentRef: parentRef, menuItems: props.subMenu, isOpen: isMenuOpen })));
    return (React.createElement(MenuItemStyled, { onClick: props.onClick, key: props.id, p: "sm", gap: "sm", display: "flex", align: "center" },
        props.iconName && React.createElement(FtsIcons, { iconName: props.iconName, width: "20px", height: "20px" }),
        React.createElement(Span, { variant: "text-3xs" }, props.title)));
};
const MenuItemStyled = styled(FtsWrapper) `
  &:hover {
    background: ${ColorsValue["greyscale-50"]};
  }
  cursor: ${(props) => (!props.disabled ? "pointer" : "default")};
`;
