export const formatPhoneNumber = (value) => {
    if (!value)
        return "";
    if (value.length <= 3)
        return `(${value.slice(0, 3)}`;
    if (value.length <= 6)
        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
};
export const onChangeInternal = (event, callback) => {
    const { target } = event;
    let { value } = target;
    value = value.replace(/\D+/g, "").trim();
    value = formatPhoneNumber(value);
    callback({
        ...event,
        target: { ...event.target, name: event.target.name, value },
    });
};
