import React, { useCallback } from "react";
import { Span } from "../../dist/ui/src/FtsText";
import { socialLinkOptions } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { Input } from "../../dist/ui/src/components/react/Input";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { setAccountDataAction } from "../../../../redux/actions/v2/account";
import isObject from "lodash/isObject";
import {
  InputColors,
  InputSizes,
  InputVariants,
} from "../../dist/types/ui/input";
import {
  selectAccountData,
  selectAccountInvalidFormFields,
} from "../../../../redux/reducers/v2/account";

export const AccountSocialRow = () => {
  const accountData = useSelector(selectAccountData);

  const dispatch = useDispatch();
  const invalidFormFields = useSelector(selectAccountInvalidFormFields);

  const onChange = useCallback(
    (event) => {
      dispatch(
        setAccountDataAction({
          ...accountData,
          [event.target.name]: event.target.value,
        }),
      );
    },
    [accountData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} py={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span weight={"medium"} variant={"text-3xs"} color={"blue-accent-500"}>
          SOCIAL LINKS
        </Span>
      </FtsColumn>
      {socialLinkOptions.map(({ label, name }) => (
        <FtsColumn size={{ xs: 4 }} key={name}>
          <Input
            name={name}
            value={accountData[name]}
            onChange={onChange}
            label={label}
            variant={InputVariants.outlined}
            size={InputSizes.sm}
            color={InputColors.accent}
            errorMessage={
              isObject(invalidFormFields) && invalidFormFields[name]
            }
          />
        </FtsColumn>
      ))}
    </FtsRow>
  );
};
