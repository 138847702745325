import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { Span } from "../../dist/ui/src/FtsText";

import { Input } from "../../dist/ui/src/components/react/Input";
import {
  InputColors,
  InputSizes,
  InputVariants,
} from "../../dist/types/ui/input";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectListingData,
  selectListingInvalidFormFields,
} from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import isObject from "lodash/isObject";
import { PhoneNumberInput } from "../../dist/ui/src/components/react/PhoneNumberInput";
import { generateListingSlug } from "../../../../utility";

export const ListingContactRow = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();
  const invalidFormFields = useSelector(selectListingInvalidFormFields);

  const onChange = useCallback(
    (event) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            [event.target.name]: event.target.value,
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"}>
      <FtsColumn size={{ xs: 12 }} mt={"2xl"}>
        <Span weight={"medium"} variant={"text-3xs"} color={"blue-accent-500"}>
          CONTACT
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <PhoneNumberInput
          name={"phoneNumber"}
          value={listingData.phoneNumber}
          onChange={onChange}
          label={"Phone (primary)*"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["phoneNumber"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <PhoneNumberInput
          name={"phoneNumberSecondary"}
          value={listingData.phoneNumberSecondary}
          onChange={onChange}
          label={"Phone (secondary)"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) &&
            invalidFormFields["phoneNumberSecondary"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <PhoneNumberInput
          name={"afterHours"}
          value={listingData.afterHours}
          onChange={onChange}
          label={"Phone (after hours)"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["afterHours"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 3 }}>
        <PhoneNumberInput
          name={"fax"}
          value={listingData.fax}
          onChange={onChange}
          label={"Fax"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={isObject(invalidFormFields) && invalidFormFields["fax"]}
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"website"}
          value={listingData.website}
          onChange={onChange}
          label={"Website"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["website"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"website2"}
          value={listingData.website2}
          onChange={onChange}
          label={"Website 2"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["website2"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"email"}
          value={listingData.email}
          onChange={onChange}
          label={"Email"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          errorMessage={
            isObject(invalidFormFields) && invalidFormFields["email"]
          }
        />
      </FtsColumn>
      <FtsColumn size={{ xs: 6 }}>
        <Input
          name={"seoUrlIdentifier"}
          value={generateListingSlug(
            listingData.id,
            listingData.name,
            listingData.city,
            listingData.state,
          )}
          onChange={onChange}
          label={"SEO URL"}
          variant={InputVariants.outlined}
          size={InputSizes.sm}
          color={InputColors.accent}
          disabled
        />
      </FtsColumn>
    </FtsRow>
  );
};
