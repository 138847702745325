import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import { FtsWrapper } from "../../dist/ui/src/FtsWrapper/FtsWrapper";

import { ColorsValue } from "../../dist/types/ui/colors";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { Span } from "../../dist/ui/src/FtsText";
import { opt } from "../../../fts/action-bar/action-sidebar/ChangeListingType";
import moment from "moment/moment";
import React from "react";
import { useSelector } from "react-redux";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { ListingInfoItem } from "./ListingInfoItem";
import Icon from "../../../fts/Icons";
import { Spacing } from "../../dist/types/ui/spacing";
import { ListingInfoBannerChip } from "./ListingInfoBannerChip";
import { selectAdminUsers } from "../../../../redux/reducers/essentialLists";

export const ListingInfoBanner = () => {
  const listingData = useSelector(selectListingData);
  const adminUsers = useSelector(selectAdminUsers);
  console.log(adminUsers);
  return (
    <FtsColumn size={{ xs: 12 }}>
      <FtsWrapper
        border={`${Spacing["4xs"]} solid ${ColorsValue["greyscale-100"]};`}
        borderRadius={"2xs"}
      >
        <FtsRow
          columnGap={"lg"}
          rowGap={"lg"}
          bg={"greyscale-50"}
          p={"lg"}
          borderRadius={"2xs"}
        >
          <FtsColumn size={{ xs: 6 }}>
            <Icon size={32} name={"RenewalsV2"} />
            <Span ml={"sm"} variant={"text-3xs"}>
              Renewal date:
            </Span>
            <Span ml={"xs"} variant={"text-3xs"} weight={"medium"}>
              {listingData.priceId === 5
                ? "No date"
                : moment.unix(listingData.renewalTimestamp).format("lll")}
            </Span>
          </FtsColumn>
          <FtsColumn
            size={{ xs: 6 }}
            display="flex"
            justify="flex-end"
            align={"center"}
            gap={"sm"}
          >
            {!listingData.user.excludeDowngrades && (
              <ListingInfoBannerChip label={"Downgrade"} />
            )}
            {!listingData.user.excludeRenewalEmails && (
              <ListingInfoBannerChip label={"Renewal"} />
            )}
            {!!listingData.user.adminCorporateAccount && (
              <ListingInfoBannerChip label={"Corporate"} />
            )}
            {!!listingData.user.adminIsMerchant && (
              <ListingInfoBannerChip label={"Merchant"} />
            )}
          </FtsColumn>
        </FtsRow>
        <FtsRow p={"lg"} borderRadius={"2xs"} columnGap={"lg"} rowGap={"lg"}>
          <ListingInfoItem
            label={"Listing type"}
            value={opt[listingData.priceId].label}
          />
          <ListingInfoItem
            label={"Created by"}
            value={
              !listingData.adminIdCreatedBy
                ? "Vendor"
                : adminUsers.find(
                    (adminUser) =>
                      adminUser.value === listingData.adminIdCreatedBy,
                  )?.label
            }
          />
          <ListingInfoItem
            label={"Created on"}
            value={moment(listingData.createdAt).format("lll")}
          />

          <ListingInfoItem
            label={"Last update date"}
            value={moment(listingData.updatedAt).format("lll")}
          />
        </FtsRow>
      </FtsWrapper>
    </FtsColumn>
  );
};
