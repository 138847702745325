import { Spacing } from "@find-truck-service/types/ui/spacing";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { FtsTagsSizes, FtsTagsVariants } from "@find-truck-service/types/ui/tags";
import { TextStyle } from "../../FtsText";
const defaultStyle = `
              display: flex;
              align-items: center;
              width: fit-content;
              height: fit-content;
              border-radius: ${Spacing["4xxl"]};`;
export const FtsTagsStyles = (props) => {
    let style = {
        [FtsTagsVariants.outlined]: {
            [FtsTagsSizes.sm]: `${defaultStyle}; padding: ${Spacing["2xs"]} ${Spacing["sm"]}; border: ${Spacing["4xs"]} ${ColorsValue[props.color]} solid;
      ${TextStyle({
                weight: "medium",
                variant: "text-4xs",
                color: props.textColor,
            })};`,
            [FtsTagsSizes.md]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; border: ${Spacing["4xs"]} ${ColorsValue[props.color]} solid;
      ${TextStyle({
                weight: "medium",
                variant: "text-3xs",
                color: props.textColor,
            })}; `,
            [FtsTagsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; border: ${Spacing["4xs"]} ${ColorsValue[props.color]} solid;
      ${TextStyle({
                weight: "medium",
                variant: "text-2xs",
                color: props.textColor,
            })};`,
        },
        [FtsTagsVariants.contained]: {
            [FtsTagsSizes.sm]: `${defaultStyle}; padding:  ${Spacing["2xs"]} ${Spacing["sm"]}; background:${ColorsValue[props.color]};
      ${TextStyle({
                weight: "medium",
                variant: "text-4xs",
                color: props.textColor,
            })};`,
            [FtsTagsSizes.md]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; background:${ColorsValue[props.color]};
      ${TextStyle({
                weight: "medium",
                variant: "text-3xs",
                color: props.textColor,
            })};`,
            [FtsTagsSizes.lg]: `${defaultStyle}; padding: ${Spacing["sm"]} ${Spacing["md"]}; background:${ColorsValue[props.color]};
      ${TextStyle({
                weight: "medium",
                variant: "text-2xs",
                color: props.textColor,
            })};`,
        },
    };
    return style[props.variant][props.size];
};
