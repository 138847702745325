import React from "react";
import TaskColumn from "./TaskColumn";
import { DragDropContext } from "react-beautiful-dnd";

import {
  getTodo,
  updateTodo,
  archiveTodo,
  assignTodoToListing,
} from "../../../redux/actions/todo";

import { connect } from "react-redux";

import "../../../assets/scss/task-management/index.scss";

import SkeletonCard from "../../../components/fts/skeleton-card";

class TaskWrapper extends React.Component {
  state = {
    activeDropdown: null,
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  handleDropdownOnScroll = () => {
    const { activeDropdown, setActiveDropdown } = this.props;
    if (activeDropdown) {
      setActiveDropdown(null);
    }
  };

  componentDidMount() {
    this.containerRef.current &&
      this.containerRef.current.addEventListener(
        "scroll",
        this.handleDropdownOnScroll,
      );
  }

  componentWillUnmount() {
    this.containerRef.current &&
      this.containerRef.current.removeEventListener(
        "scroll",
        this.handleDropdownOnScroll,
      );
  }

  isLowerCase(str) {
    return str === str.toLowerCase();
  }

  setActiveDropdown = (toDoId) => {
    if (this.state.activeDropdown === toDoId) {
      this.setState({
        activeDropdown: null,
      });
    } else {
      this.setState({ activeDropdown: toDoId });
    }
  };
  getSkeletons = () => {
    let skeletons = [];

    const min = 1;
    const max = 6;
    const rand = min + Math.random() * (max - min);
    let i = 1;
    while (i <= rand) {
      skeletons.push(<SkeletonCard key={i} />);
      i++;
    }
    return skeletons;
  };
  render() {
    const {
      reminders,
      onDragStart,
      onDragEnd,
      columns,
      userRef,
      loading,

      list,
      callToAction,
      handleArchive,
      user,
      handleUnArchive,
      handleTodoTypeUpdate,
      handleTodoUsersUpdate,
      handleEmptyCols,
    } = this.props;

    return (
      <div className="task-management-wrapper">
        {!reminders || loading ? (
          <div className="task-management__container" ref={this.containerRef}>
            {Object.keys(columns).map((e, i) => {
              return (
                <div key={i + 10000} className="tasks-column">
                  {this.getSkeletons()}
                </div>
              );
            })}
          </div>
        ) : (
          reminders && (
            <div className="task-management__container" ref={this.containerRef}>
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                {Object.keys(reminders).map((columnName, i) => {
                  let column = columns.find(
                    (e) => e.description.toLowerCase() === columnName,
                  );
                  if (!column || !reminders[columnName].todos.length)
                    return null;

                  return (
                    <TaskColumn
                      key={i + 1000}
                      // isLoading={isLoading}
                      dataLength={reminders[columnName].todos.length}
                      counts={reminders[columnName].count}
                      reminders={reminders[columnName].todos}
                      callToAction={callToAction}
                      // loading={loading}
                      activeDropdown={this.state.activeDropdown}
                      setActiveDropdown={this.setActiveDropdown}
                      handleArchive={handleArchive}
                      handleUnArchive={handleUnArchive}
                      handleTodoTypeUpdate={handleTodoTypeUpdate}
                      handleTodoUsersUpdate={handleTodoUsersUpdate}
                      handleEmptyCols={handleEmptyCols}
                      getEmptyColumns={() => {
                        let types = [];
                        columns.map((e) => {
                          if (
                            e.type !== "note" &&
                            e.description.toLowerCase() !== columnName
                          )
                            types.push(e);
                          return null;
                        });
                        return types;
                      }}
                      // getEmptyColumns={getEmptyColumns}
                      list={list}
                      userRef={userRef}
                      user={user}
                      {...{ column }}
                    />
                  );
                })}
              </DragDropContext>
            </div>
          )
        )}
      </div>
    );
  }
}

export default connect(null, {
  getTodo,
  updateTodo,
  archiveTodo,
  assignTodoToListing,
})(TaskWrapper);
