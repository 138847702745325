import React, { useCallback } from "react";
import { Input } from "../Input";
import Checkbox from "../Checkbox";
import { Span } from "../../../FtsText";
import { FtsRow } from "../../../FtsRow/FtsRow";
import { RichTextEditor } from "../RichTextEditor";
import FtsIcons from "../../../FtsIcons/FtsIcons";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { getErrorMsg } from "../CreditCardForm/helpers";
import { FtsButton } from "../../../FtsButton/FtsButton";
import { FtsColumn } from "../../../FtsColumn/FtsColumn";
import { Spacing } from "@find-truck-service/types/ui/spacing";
import { FtsWrapper } from "../../../FtsWrapper/FtsWrapper";
import { ColorsValue } from "@find-truck-service/types/ui/colors";
import { checkboxOptLabels, mobileOptIn, primaryOptIn } from "./constants";
import { InputColors, InputSizes, InputVariants } from "@find-truck-service/types/ui/input";
import { ButtonColorsValue, ButtonSizes, ButtonVariants } from "@find-truck-service/types/ui/button";
export const AltContactForm = (props) => {
    const { title, onSubmit, handleOnChange, onRemoveAltContact, closeAltContactForm, submitButtonTitle, isEdit, altContactData, invalidFormFields, handleCheckboxChange, } = props;
    const onNameChange = useCallback((event, name) => {
        handleOnChange(name, event);
    }, []);
    return (React.createElement(FtsColumn, { size: { xs: 12 }, borderRadius: "2xs", py: "lg", px: "2xl", border: `${Spacing["4xs"]} solid ${ColorsValue["blue-accent-300"]}` },
        React.createElement(FtsRow, { columnGap: "lg", rowGap: "lg" },
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(Span, { variant: "text-2xs", color: "greyscale-800" }, title)),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(Input, { required: true, label: "First name", size: InputSizes.sm, color: InputColors.accent, name: "altContactFirstName", variant: InputVariants.outlined, value: altContactData.altContactFirstName, errorMessage: getErrorMsg(invalidFormFields, "altContactFirstName"), onChange: (e) => onNameChange(e, "altContactFirstName") })),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(Input, { required: true, label: "Last name", size: InputSizes.sm, color: InputColors.accent, name: "altContactLastName", variant: InputVariants.outlined, value: altContactData.altContactLastName, errorMessage: getErrorMsg(invalidFormFields, "altContactLastName"), onChange: (e) => onNameChange(e, "altContactLastName") })),
            React.createElement(FtsColumn, { size: { xs: 4 } },
                React.createElement(Input, { size: InputSizes.sm, label: "Title/Position", color: InputColors.accent, name: "altContactJobTitle", variant: InputVariants.outlined, value: altContactData.altContactJobTitle, onChange: (e) => onNameChange(e, "altContactJobTitle") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(PhoneNumberInput, { size: InputSizes.sm, label: "Phone (primary)", color: InputColors.accent, name: "altContactPhoneNumber", variant: InputVariants.outlined, value: altContactData.altContactPhoneNumber, onChange: (e) => onNameChange(e, "altContactPhoneNumber"), errorMessage: getErrorMsg(invalidFormFields, "altContactPhoneNumber") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(PhoneNumberInput, { size: InputSizes.sm, color: InputColors.accent, label: "Phone (secondary)", variant: InputVariants.outlined, name: "altContactPhoneNumberSecondary", value: altContactData.altContactPhoneNumberSecondary, onChange: (e) => onNameChange(e, "altContactPhoneNumberSecondary"), errorMessage: getErrorMsg(invalidFormFields, "altContactPhoneNumberSecondary") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(PhoneNumberInput, { label: "Mobile", size: InputSizes.sm, color: InputColors.accent, name: "altContactMobileNumber", variant: InputVariants.outlined, value: altContactData.altContactMobileNumber, onChange: (e) => onNameChange(e, "altContactMobileNumber"), errorMessage: getErrorMsg(invalidFormFields, "altContactMobileNumber") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(FtsWrapper, { mb: "2xs" },
                    React.createElement(Span, { variant: "text-3xs", color: "greyscale-500" }, "Opt-in")),
                React.createElement(FtsRow, { columnGap: "lg", py: "xs" }, mobileOptIn.map((name, index) => (React.createElement(FtsColumn, { size: { xs: 4 }, key: name },
                    React.createElement(Checkbox, { label: checkboxOptLabels[index], checked: !!altContactData[name], onClick: () => handleCheckboxChange(name, !!altContactData[name]) })))))),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(Input, { label: "Email", size: InputSizes.sm, name: "altContactEmail", color: InputColors.accent, variant: InputVariants.outlined, value: altContactData.altContactEmail, onChange: (e) => onNameChange(e, "altContactEmail"), errorMessage: getErrorMsg(invalidFormFields, "altContactEmail") })),
            React.createElement(FtsColumn, { size: { xs: 6 } },
                React.createElement(FtsWrapper, { mb: "2xs" },
                    React.createElement(Span, { variant: "text-3xs", color: "greyscale-500" }, "Opt-in")),
                React.createElement(FtsRow, { columnGap: "lg", py: "xs" }, primaryOptIn.map((name, index) => (React.createElement(FtsColumn, { size: { xs: 4 }, key: name },
                    React.createElement(Checkbox, { label: checkboxOptLabels[index], checked: !!altContactData[name], onClick: () => handleCheckboxChange(name, !!altContactData[name]) })))))),
            React.createElement(FtsColumn, { size: { xs: 12 } },
                React.createElement(RichTextEditor, { value: altContactData.note, onChange: (e) => handleOnChange("note", { target: { value: e } }), height: "120px", label: "Note" })),
            React.createElement(FtsColumn, { size: { xs: 8 } }, isEdit && (React.createElement(FtsButton, { onClick: onRemoveAltContact, variant: ButtonVariants["text"], color: ButtonColorsValue["accent"], size: ButtonSizes.sm },
                React.createElement(FtsIcons, { iconName: "Trash" }),
                React.createElement(Span, { variant: "text-3xs", color: "red-error-500", ml: "xs" }, "Remove")))),
            React.createElement(FtsColumn, { size: { xs: 4 }, textAlign: "end" },
                React.createElement(FtsButton, { onClick: closeAltContactForm, variant: ButtonVariants["text"], color: ButtonColorsValue["accent"], size: ButtonSizes.sm, mr: "lg" },
                    React.createElement(Span, { variant: "text-3xs", color: "blue-accent-500", py: "3xs" }, "Cancel")),
                React.createElement(FtsButton, { onClick: onSubmit, variant: ButtonVariants["contained"], color: ButtonColorsValue["accent"], size: ButtonSizes.sm },
                    React.createElement(Span, { variant: "text-3xs", color: "greyscale-0", py: "3xs" }, submitButtonTitle))))));
};
