import { useDispatch, useSelector } from "react-redux";
import { FtsRow } from "../../dist/ui/src/FtsRow/FtsRow";
import { FtsColumn } from "../../dist/ui/src/FtsColumn/FtsColumn";
import React, { useCallback } from "react";
import { selectListingData } from "../../../../redux/reducers/v2/listing";
import { getListingDataByIdSuccess } from "../../../../redux/actions/v2/listings";
import { Span } from "../../dist/ui/src/FtsText";
import { RichTextEditor } from "@find-truck-service/ui/src/components/react/RichTextEditor";

export const ListingDescriptionRow = () => {
  const listingData = useSelector(selectListingData);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (value) => {
      dispatch(
        getListingDataByIdSuccess({
          data: {
            ...listingData,
            description: value,
          },
        }),
      );
    },
    [listingData],
  );

  return (
    <FtsRow columnGap={"lg"} rowGap={"lg"} mt={"2xl"}>
      <FtsColumn size={{ xs: 12 }}>
        <Span weight={"medium"} variant={"text-3xs"} color={"blue-accent-500"}>
          DESCRIPTION
        </Span>
      </FtsColumn>
      <FtsColumn size={{ xs: 8 }}>
        <RichTextEditor
          value={listingData.description}
          onChange={onChange}
          className={"description"}
          placeholder="Tell your customers about your company and services provided. Example: Whether you are moving freight across the country or making deliveries across town - Cummins knows that every Every Minute Counts. Because of this, Cummins provides the service support you need, when you need it. Whether in the shop or in the field, every Cummins location has the technical expertise, factory support, and experience to deliver fast high quality repairs through our global QuickServe process. QuickServe is a universal approach that provides you with: Personalized Service Fast Response & Diagnosis "
        />
      </FtsColumn>
    </FtsRow>
  );
};
