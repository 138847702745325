import styled from "styled-components";
import { FtsWrapper } from "../FtsWrapper/FtsWrapper";
import { defaultStyleProps } from "../default";
export const FtsSideOutContainer = styled(FtsWrapper) `
  height: 100%;
  overflow: auto;
  background: #fff;
  position: absolute;
  z-index: ${(props) => (props.isSubSideOut ? 1035 : 1033)};
  animation: ${(props) => (props.isOpen ? "slideIn" : "slideOut")} 0.3s ease-out;
  width: 916px;
  right: 0;
  transition:
    visibility,
    width 0.2s ease-out;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  @keyframes slideIn {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }

  @keyframes slideOut {
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }
  ${defaultStyleProps};
`;
